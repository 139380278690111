@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');

body{
        width: 100vw;
}



@media only screen and (max-width: 1051px){
    .create-acc-btn, .login-btn{
        width: 130px;
        font-size: 10px;
        
    }
/* 
    .landingbutton-container{
        display: none ;
} */
/* 
.logo-container{
    width: 30%;
    margin: 0 auto;
} */
    
}

.navbar-bg{
    background: #FFFFFF;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.25);
    width: 100vw;
    height: 76px;
    z-index: -999;
}

.navbar-container{
  width: 80%;
  margin: 0 auto;
}

.navbar-contents{
    display: flex;
    justify-content: space-between;
   
}

.logo-contained{
    padding-top: 21px;
   
}

.logo{
     width: 80%;
}

.menubar{
    padding-top: 21px;
}

.landingbtn-container{
 padding-top: 15px;
}


.login-btn{
    padding: 15px 20px;
    gap: 10px;
    margin-right: 24px;
    width: 159px;
    height: 48px;

    border: 1.5px solid rgba(14, 170, 79, 0.2);
    border-radius: 4px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    background-color: #FFFFFF;
    color: #0EAA4F;
}

.create-acc-btn{
    padding: 15px 20px;
    gap: 10px;

    width: 159px;
    height: 48px;

    background: #0EAA4F;
    border-radius: 4px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border: none;
    color: #FFFFFF;
}

.form-section {
    justify-content: space-between;
    width: 30%;
    align-items: center;
    padding: 1% 2%;
    gap: 2%;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    height: 43% !important;
}