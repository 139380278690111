   @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');
   
    @media only screen and (max-width: 900px){
        .partner-bg{
            margin-top: 610px !important;
            height: 230px !important;
            
        }
        
        .mobile-partner-logos{
            display: flex;
            justify-content: space-around;
            margin-bottom: 45px;

        }

    }
    
    
    @media only screen and (max-width: 680px){
        .partner-container{
            margin-top: 100% ;
        }
    }


.partner-bg{
    width: 100%;
    height: 179px;
    background: #F4F4F4;
    margin-top: 116px;
}

.partner-header{
    width: 100%;
    text-align: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #667085;
    padding-top: 24px;
}

.partner-logos{
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.henley-img, .cowry-img, .semicolon-img{
   width: 15%;
}


.fcmb-img{
    width: 10%;
}
