@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');

@media only screen and (max-width: 1152px){

    .landinghero-header{
        font-size: 40px !important;
    }

    .hero-subheader-contents{
    width: 90% !important;
}

.hero-header-contents{
    width: 100% !important;
}
}

@media only screen and (max-width: 1051px){
    .create-acc-btn, .login-btn{
        width: 130px;
        font-size: 10px;
    }

    .login-btn{
        margin-right: -10px;
    }
    
}


@media only screen and (max-width: 956px){
    .welcomePage-img{
    width: 80% !important;
    height: 30px !important;
} 

 .landinghero-header{
        font-size: 30px !important;
       
    }

    .create-acc{
    margin-right: 10px !important;
}

    .welcome-img{
        width: 100%;
    }

}

@media only screen and (max-width: 898px) {
     .welcome-container{
        flex-direction: column;
        /* margin-bottom: 10% !important; */
    }

    .welcome-img{
        margin-top: -10%;
        width: 81vw;
           margin-bottom: 10% !important;
    }

    .hero-header-contents{
        width: 100% !important;
        margin: 0 auto;
    }
    
    .landinghero-header{
        font-size: 70px !important;
        line-height: 90px !important;
        
    }

    .landingbtn-container{
        flex-direction: column !important;
        /* margin-top: 357px ; */
    }

      .create-acc-btn, .login-btn{
          width: 81vw !important;
       margin-bottom: 20px;
       font-size: 20px;
       padding-top: 20px;
    padding-bottom: 39px;
    }

    
.landinghero-subheader{
    font-size: 24px !important;
    line-height: 40px !important;
   
    
}
    
}


@media only screen and (max-width: 680px){
   
    .landinghero-header{
        width: 85% !important;
        font-weight: 600;
        font-size: 45px !important;
        line-height: 70px !important;
        align-items: center;
        letter-spacing: -0.04em;
        margin-top: -50px;
        color: #052942;
        margin-left: 15px;
    }

    .landinghero-subheader{

        font-size: 20px !important;
        line-height: 35px !important;
        width: 90%;
        margin: 0 auto;
        margin-top: 12px;
        margin-bottom: -35px;
    }

    /* .landingbtn-container{
        display: none;
    } */

    .welcome-img{
        margin-top: -30px;
        margin-bottom: 10% !important;
        width: 100vw !important;
    }
    
        .create-acc-btn, .login-btn{
          width: 100vw !important;
       margin-bottom: 20px;
       font-size: 17px;
    }


    .hero-msg-container{
        width: 100% !important;
    }

    .hero-subheader-contents{
         width: 100% !important;
    }

    .hero-header-contents{
        display: flex;
        justify-content: center;
         width: 100% !important;
    }

    .welcomePage-img{
         width: 100% !important;
    }

    .hero-subheader-contents{
         display: flex;
        justify-content: center;
       
    }
}


.hero-msg-container{
     width: 80%;
    margin: 0 auto;
    
}


.welcome-container{
    display: flex;
    /* justify-content: space-between; */
}


.landinghero-header{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: bolder;
    font-size: 54px;
    line-height: 64px;
    letter-spacing: -0.04em;
    color: #052942;
    width: 98% !important;

}

.landinghero-header span{
    color: #0D9B48;
}

.hero-header-contents{
    width: 75%;
}

.hero-subheader-contents{
    width: 70%;
}

.landinghero-subheader{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #5C6D79;
}

.create-acc{
    margin-right: 24px;
}

.hero-group{
    margin-top: 105px;
}

.welcomePage-img{
    margin-top: 112px;
}