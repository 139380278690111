/* Startup-Content */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');


.startupContent-wrapper{
    width: 85%;
    margin: 0 auto;
  
}

@media only screen and (max-width: 900px) {
    .startupContent-container{
        flex-direction:column;
    
}

@media only screen and (max-width: 600px) {
    .css-1j2dqe7-MuiTypography-root {
        margin: 0;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 30px;
        font-weight: 400px;
        color: #667085;
        width: 100%;
        font-size: 18px !important;
    }
}

.trainee-header{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #0EAA4F;
    padding-top: 12px;
    width: 95%;
    margin: 0 auto;
    height: 55px;
    border-bottom: 2.5px solid #0EAA4F ;
    background: rgba(14, 170, 79, 0.06);
    border-radius: 12px 12px 0px 0px;
}



.trainee-header2{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #C03006;
    margin-bottom: 35px;
    padding-top: 12px;
    width: 100%;
    height: 55px;
    border-bottom: 2.5px solid #C03006 ;
    background: rgba(192, 48, 6, 0.06);
    border-radius: 12px 12px 0px 0px;
}

.trainee-header3{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #0EAA4F;
     margin-bottom: 35px;
    padding-top: 12px;
    width: 100%;
    height: 55px;
    border-bottom: 2.5px solid #0EAA4F ;
    background: rgba(14, 170, 79, 0.06);
    border-radius: 12px 12px 0px 0px;
}
}

.startupContent-container{
    display: flex;
    
}

/* Startup-Studio */

.tabs-section{
    font-size: 18px;
}

.imageSlide{
    animation: introLoad 2s forwards;
}

.imageSlideLeft{
    animation: introLoadLeft 2s forwards;
}

 @keyframes introLoad {
    from {
        transform: translateX(100px);
    }

    to {
        transform: translateX(0);
    }
}

 @keyframes introLoadLeft {
    from {
        transform: translateX(-100px);
    }

    to {
        transform: translateX(0);
    }
}


