@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');

@media only screen and (max-width: 600px) {
    .faq-bg{
        padding-top: 116px !important;
    }
}

.faq-bg{
    background-color: white;
    width: 100%;
    height: 918px;
    padding-top: 64px;
    font-family: 'IBM Plex Sans';
}

.faq-header{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #263238;
}


.faq-sub{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    text-align: center;
    color: #263238;
}

.faq-questions{
    width: 75%;
    margin: 0 auto;
}
