@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');

@media only screen and (max-width: 900px) {
    .footer{
        flex-direction: column;
    }

    .footer-mobile-text{
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #5C6D79;
    }

    .footer-info{
        flex-direction: column;
    }

    .footer-bg{
        height: 1142px !important;
        margin-top: 39%;
    }

    .footer-socials{
            position: relative;
            left: 110px;
    }

    .footer-divider{
        display: none;
    }
    .footer-faq{
        margin-bottom: 16px !important;
    }

    .footer-link{
        margin-bottom: 48px;
    }

    .form-control, .prefer-institute{
        width: 80vw !important;
        margin-bottom: 10px;
    }

    .chevron-down{
            position: absolute;
            right: -53vw !important;
            margin-bottom: 10px;
    }

    .footer-form-btn {
        width: 80vw !important;
    }

    .prefer-options {
        position: absolute;
        margin-top: -10px;
        width: 80vw !important;
    }

} 

@media only screen and (max-width: 600px) {
 
    .footer-logo{
        width: 50% !important;
    }

}


@media only screen and (max-width: 420px) {
     .footer-bg {
        margin-top: 65% !important;
    }
}


@media only screen and (max-width: 400px) {
 
    .footer-logo{
        width: 60% !important;
    }

}

@media only screen and (max-width: 371px) {
     .footer-bg {
        margin-top: 75% !important;
    }
}

@media only screen and (max-width: 340px) {
     .footer-bg {
        margin-top: 90% !important;
    }
}

@media only screen and (max-width: 322px) {
     .footer-bg {
        margin-top: 100% !important;
    }
}


@media only screen and (max-width: 272px) {
     .footer-bg {
        margin-top: 200vw !important;
    }
}

@media only screen and (max-width: 250px) {
     .footer-bg {
        margin-top: 280vw !important;
    }
}




.footer-bg{
    width: 100%;
    height: 533px;
    background: #F9FAFE;
}

.footer{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.footer-logo{
    width: 35%;
    padding-top: 51px;
    padding-bottom: 45px;
}

.footer-question{
    color: #0EAA4F;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    list-style: none;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 20px;
    text-transform: capitalize;
    border-bottom: 2px solid green;
    animation: appear 1s linear;
    width: 100%;
    text-decoration: none;
}


.footer-question:hover{
     color: #0EAA4F;
}

.footer-question-container{
     list-style: none;
     text-decoration: none;
     padding-bottom: 20px;
    cursor: pointer;
    flex-direction: column;
}

    @keyframes appear{
        from{
            width: 0;
        }
        to{
            width: 100%;
        }
    }

.footer-header{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
}

.footer-faq:hover{
   
    color: #5C6D79;
}
.footer-faq{
    text-decoration: none;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    color: #bfdaca;
    margin-bottom: 10px;
    margin-top: 59px !important;
    font-size: 15px;
    line-height: 20px;
    color: #5C6D79;
    width: 100%;
}

.footer-sub{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 156%;
    color: #5C6D79;
    width: 60%;
}

.footer-info{
    display: flex;
    column-gap: 72px;
    
}

.footer-socials{
    margin-left: -120px !important;
}

.footer-contact{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #101828;
}

.footer-in-touch{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #5F646F;
}

.footer-header-form-bg{
    padding: 4.7423px;
    gap: 6.32px;
   
    width: 20vw;
    height: 44.29px;

    background: #FFFFFF;
    border: 0.790383px solid rgba(14, 170, 79, 0.3);
    border-radius: 6.32306px;
}

.footer-form-header1{
    padding: 7.90383px 11.0654px;
    gap: 6.32px;
    width: 30%;
    height: 34.81px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12.6461px;
    line-height: 19px;
     border: none;
    color: #FFFFFF;
    background: #0EAA4F;
    box-shadow: 0px 0.790383px 2.37115px rgba(16, 24, 40, 0.1), 0px 0.790383px 1.58077px rgba(16, 24, 40, 0.06);
    border-radius: 3.16153px;text-align: center;
}

.footer-flex-container{
    display: flex;
    column-gap: 30p;
}

.waiting-list{
    margin-top: 32px;
}

.footer-form-header2{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-size: 12.6461px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
    margin-left: 20px;
    margin-top: 8px;
}

.form-group{
      font-family: 'IBM Plex Sans';
       font-weight: 500;
        margin-top: 14px;
        width: 25vw;
}

.footer-label{
    font-weight: 700;
}

.footer-form-btn{
    padding: 12px 8px;
    gap: 8px;
    color: white;
    width: 100%;
    height: 43px;
    background: #0D9B48;
    border-radius: 4px;
    text-decoration: none;
    outline-style: none;
    border: none;
    margin-top: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
}

.footer-form-btn:hover{
     color: white;
}



.footer-divider{
    width: 90%;
    margin: 0 auto;
    height: 0px;
    left: 120px;
    top: 456px;

    border: 0.5px solid rgba(211, 211, 211, 0.7);
}

.footer-copyright{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #646464;
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
    opacity: 0.6;
}

.prefer-institute{
    width: 100%;
    height: 40px;
    position: relative;
    display: flex;
    overflow-x: auto;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    gap:3px;
    overflow-y: hidden;
    padding-bottom: 20px;
    scrollbar-width: none;
    padding-left: 12px;
}

.prefer-institute::-webkit-scrollbar{
    display: none;
}

.prefer-options{
    position: absolute;
    width: 25vw;
    min-height: 203px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
}

.chevron-down{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
    height: 15px;
    width: 15px;
    background-color: white;
}

.input-preferred{
    /* width: fit-content; */
    height: 29px;
    border: none;
    outline-style: none;
    background: #EFF8FF;
    border-radius: 16px;
    margin-left: 8px;
    max-width: 100%;
    padding-left: 20px;
}

.footer-plus{
    /* position: absolute; */
    width: 20px;
    height: 20px;    
    background: #D9D9D9;
    border-radius: 30px;
    cursor: pointer;
    color: white;
    background-color: #0D9B48;
    margin-right: 10px;
}


.footer-plus-wrap{
   font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #6941C6;
    padding: 4px 8px;
    gap: 4px;
    width: 110px;
    border: none;
    margin-top: 8px;
    margin-right: 8px;
    height: 26px;
    background: #F9F5FF;
    border-radius: 16px;
    margin-bottom: 16px;
    
}


.footer-plus-wrapper{
   font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #175CD3;
    padding: 4px 8px;
    gap: 4px;
    border: none;
    width: 280px;
    height: 26px;
    background: #EFF8FF;
    border-radius: 16px;
    margin-bottom: 16px;
    width: 179px;
    margin-top: 8px;
    margin-right: 8px;
   
}

.footerPlus-container {
    position: relative;
    font-size: 12px;
    flex: none;
    width: fit-content;
    padding-right: 30px;

}

.footer-close-dropdown{
     position: absolute;
    right: -1px;
    top: 9px;
    width: 30px;
    height: 20px;
    border-radius: 20px;
    color: black;
    font-weight: 900;
    cursor: pointer;
}

.footer-close{
    position: absolute;
    right: 8px;
    top: 7px;
    width: 15px;
    height: 20;
    background-color: red;
    border-radius: 20px;
    color: white;
    font-weight: 900;
    cursor: pointer;
}

.footer-email-icon{
    margin-top: 8px;
}



.footer-facebook-icon{
    margin-right: 15px;
}

.footer-add-btn{
    

    padding: 4px 8px;
    gap: 4px;
    width: 15%;
    height: 40px;
    border-radius: 24px;
    background: #0EAA4F;
    border: none;
    color: white;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}