@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');

.value-bg{
    background-color: white;
    width: 100%;
    height: 592px;
}


@media only screen and (max-width: 317px ) {
    .value-bg{
       
        height: 1300px;
    }
}

.value-header{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #263238;
    padding-top: 107px;
}

.section-one-container{
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;

}@media only screen and (max-width: 660px ) {
   .section-one-container{
    flex-direction: column;
   }

   .sectionOne-sub {
    font-size: 16px !important;
}

   .dashed-lines{
    display: none;
   }

    .sectionOne-header{
        font-size: 18px !important;
    }

  .value-bg{
        height: 1000px !important;
    }


.sectionOne-description{
    width: 100% !important;
    font-size: 18px !important;
}

.section-one{
    margin-top: 41px !important;
}

.value-header{
    padding-top: 64px !important;
}

}

.section-one{
    width: 100%;
    text-align: center;
    margin-top: 80px;
}

.sectionOne-header{
    font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 31px;
letter-spacing: -0.01em;
margin-top: 4px;
color: #383838;
}

@media only screen and (max-width: 880px ) {
    .sectionOne-header{
        font-size: 16px;
    }
}

@media only screen and (max-width: 770px ) {
    .sectionOne-header{
        font-size: 14px;
    }
}

.sectionOne-sub{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    width: 100%;
    color: #000000;
}

.sectionOne-description{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    width: 73%;
    margin: 0 auto;
    line-height: 24px;
    color: #383838;
}

.dashed-lines{
    border: 1px dashed #000000;
    width: 0px;
    height:301px;
    margin-top: 50px;

}


@media only screen and (max-width: 996px ) {
    .dashed-lines{
        margin-top: 65px;
    }
}

@media only screen and (max-width: 866px ) {
    .dashed-lines{
        margin-top: 70px;
    }

    .sectionOne-description{
           font-size: 13px;
              width: 85%;
    }

}