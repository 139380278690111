@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');

@media only screen and (max-width: 860px){
    .intro-header-content{
    
    padding-top: 40px !important;
    padding-bottom: 12px !important;

}

.intro-header{
     font-size: 18px !important ;
}

.intro-subheader{

      font-weight: 400;
    font-size: 14px;
    
}
}

@media only screen and (max-width: 562px){
    .intro-subheader{
        line-height: 20px !important;
        width: 70% !important;
        /* margin-top: -10px !important; */
        font-weight: 400;
        font-size: 14px;
    }
}

@media only screen and (max-width: 350px){
      .intro-subheader{
        line-height: 20px !important;
        width: 70% !important;
        line-height: 20px !important;
        margin-top: -10px !important;
        font-weight: 400;
        font-size: 11px !important;
    }

    .intro-header{
     font-size: 16px !important ;
     margin-top: -10px ;
}
}



.intro-bg{
    width: 100%;
    height: 300px;
    background-color: #EF5B0C;
}

.intro-header{
     font-weight: 900;
}

.intro-header-content{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-size: 22px;
    line-height: 42px;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    text-align: center;
    padding-top: 90px;
    padding-bottom: 16px;

}

.intro-subheader-content{
    width: 100%;
    text-align: center;
    display: inline-block;
    justify-content: center;
    align-items: center;
}

.intro-subheader{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    width: 61%;
    margin: 0 auto;
    color: #FFFFFF;
    padding-bottom: 90px;
}