@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=IBM+Plex+Serif:wght@300&family=Inter:wght@300&family=Poppins:wght@200&display=swap');

.work-bg{
    width: 100%;
    height: 100%;
    background: #FBFBFB;
    padding-bottom: 7% ;
}

@media only screen and (max-width: 900px) {
    .work-bg{
        margin-top: 1783px !important;
       
    }
      .work-img-container{
        width: 100% !important;
    }
    
}

@media only screen and (max-width: 800px) {
    .work-bg{
            margin-top: 1500px !important;
       
    }

    
    
}



@media only screen and (max-width: 600px) {
    .work-bg{
       background: white !important;
    
    }

  .work-header{
    padding-top: 7rem !important;
  }
    
}


@media only screen and (max-width: 500px) {
    .work-header-container{
    position: relative;
    top: 60px;
    }
}



.work-header{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.04em;
        padding-top: 5%;

    color: #101828;
}

.work-img-container{
    width: 65%;
    margin: 0 auto;
}
.work-img{
    width: 100%;
    margin-top: 50px;
}